import React from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import { Row, Col, Button, Card, Carousel } from "react-bootstrap";
import Video from "@components/Video";

import { poloCarousels, clientData, freeServices } from "@utils/static-data";

import styles from "./benefits.module.scss";

const Benefits = () => {
    const images = useStaticQuery(graphql`
        query IndexQuery {
            carousel: allFile(
                filter: {
                    dir: { regex: "/(products)/" }
                    name: { regex: "/(carousel)/" }
                }
                sort: { fields: name, order: ASC }
            ) {
                edges {
                    node {
                        id
                        base
                        childImageSharp {
                            fluid(maxHeight: 605, maxWidth: 453, quality: 80) {
                                aspectRatio
                                base64
                                sizes
                                src
                                srcSet
                            }
                        }
                    }
                }
            }
            clientLogo: allFile(
                filter: {
                    dir: { regex: "/(client-logo)/" }
                    name: { regex: "/()/" }
                }
                sort: { fields: name, order: ASC }
            ) {
                edges {
                    node {
                        id
                        base
                        childImageSharp {
                            fluid(maxHeight: 55, quality: 80) {
                                aspectRatio
                                base64
                                sizes
                                src
                                srcSet
                            }
                        }
                    }
                }
            }
            csImage: file(relativePath: { eq: "products/mentahan-07.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 465, quality: 80) {
                        aspectRatio
                        base64
                        sizes
                        src
                        srcSet
                    }
                }
            }
            previewSeragam: file(
                relativePath: { eq: "products/menatahan-08.jpg" }
            ) {
                childImageSharp {
                    fluid(maxHeight: 205, quality: 80) {
                        aspectRatio
                        base64
                        sizes
                        src
                        srcSet
                    }
                }
            }
            diagram: file(relativePath: { eq: "chart-2024.png" }) {
                childImageSharp {
                    fluid(maxHeight: 300, quality: 80) {
                        aspectRatio
                        base64
                        sizes
                        src
                        srcSet
                    }
                }
            }
        }
    `);

    const renderPoloCarousel = () => {
        return images.carousel.edges.map(({ node }) => (
            <Carousel.Item key={node.id}>
                <Img
                    fluid={node.childImageSharp.fluid}
                    className="h-100 w-100"
                    imgStyle={{ objectFit: "cover", objectPosition: "top" }}
                    alt="polo-shirt"
                />
            </Carousel.Item>
        ));
    };

    const renderOurClient = () => {
        let sortedClientData = [];

        if (images.clientLogo.edges.length > 0) {
            for (let i = 0; i < images.clientLogo.edges.length; i += 6) {
                sortedClientData.push(images.clientLogo.edges.slice(i, i + 6));
            }
        }

        return sortedClientData
            .filter(group => group.length === 6)
            .map((group, id) => (
                <Carousel.Item key={id}>
                    <Row>
                        {group.map(({ node }) => (
                            <Col
                                key={node.id}
                                xs={4}
                                md={4}
                                className="mb-3 d-flex align-items-center"
                                style={{ width: 55, height: 55 }}
                            >
                                <Img
                                    fluid={node.childImageSharp.fluid}
                                    className={`h-100 w-100`}
                                    alt="clients"
                                    imgStyle={{ objectFit: "contain" }}
                                />
                            </Col>
                        ))}
                    </Row>
                </Carousel.Item>
            ));
    };

    const renderServices = () => {
        return freeServices.map((service, idx) => (
            <Col
                key={idx}
                xs={4}
                md={4}
                className="mb-3 d-flex flex-column justify-content-end align-items-center"
                style={{ fontSize: 11 }}
            >
                <img
                    src={require(`../../assets/images/icons/${service.img}`)}
                    style={{ width: "45%" }}
                    alt="our-services"
                />
                <span className="font-weight-bold text-center mt-1">
                    {service.label}
                </span>
                <span>Gratis</span>
            </Col>
        ));
    };

    return (
        <section className="benefits mb-5">
            <Row>
                <Col xs={12} md={8} className="mb-4">
                    <Card className={styles.card}>
                        <Card.Body className={styles.cs}>
                            <div className={styles.textContainer}>
                                <h4>24 Jam dalam 7 Hari</h4>
                                <span>
                                    CS kami yang ramah
                                    <br />
                                    selalu setia mendampingi Anda
                                </span>
                            </div>
                            <div className={styles.imgWrapper}>
                                <div className={styles.imgContainer}>
                                    <Img
                                        fluid={
                                            images.csImage.childImageSharp.fluid
                                        }
                                        alt="customer-services"
                                    />
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} md={4} className="mb-4">
                    <Card
                        className={styles.card}
                        style={{ overflow: "hidden" }}
                    >
                        <Card.Body className={styles.benefits1}>
                            <p>
                                Partner Terbaik Anda Dalam Membuat Kaos Polo
                                Impian
                            </p>
                            <div className={styles.imgWrapper}>
                                <div className={styles.imgContainer}>
                                    <Img
                                        fluid={
                                            images.previewSeragam
                                                .childImageSharp.fluid
                                        }
                                        className="h-100"
                                        alt="polo-impian"
                                    />
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={3} className="mb-4">
                    <Card className={styles.card}>
                        <Card.Body className={styles.carousels}>
                            <Carousel
                                indicators={false}
                                controls={false}
                                pause={false}
                                touch={false}
                                interval={1500}
                                className={`overflow-hidden ${styles.borderRadius}`}
                            >
                                {renderPoloCarousel()}
                            </Carousel>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} md={9} className="mb-4">
                    <Card className={styles.card}>
                        <Card.Body className={styles.growth}>
                            <div className={styles.imgWrapper}>
                                <div className={styles.imgContainer}>
                                    <Img
                                        fluid={
                                            images.diagram.childImageSharp.fluid
                                        }
                                        className="w-100 h-100"
                                        imgStyle={{
                                            objectFit: "unset",
                                            objectPosition: "unset",
                                        }}
                                        alt="grafik-proyek"
                                    />
                                </div>
                            </div>
                            <p className={styles.text1}>
                                Dalam kurun waktu 10 Tahun,
                                <br />
                                Kami telah membantu Lebih dari <br />
                                <span className={styles.textOrange}>
                                    3.923 Project
                                </span>{" "}
                                pembuatan seragam.
                            </p>
                            <p className={styles.text2}>
                                Semakin banyak klien baru yang kami bantu setiap
                                tahunnya. Dan juga, bertambah pula pelanggan
                                setia yang selalu terpuaskan dengan seragamnya.
                            </p>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={4} className="mb-4">
                    <Card className={`bg-transparent ${styles.card}`}>
                        <Card.Body className="p-0">
                            <div className={styles.video}>
                                <Video
                                    quality="sd"
                                    title="Cara Order"
                                    link="https://www.youtube.com/embed/tVRvFQIMcaA"
                                />
                            </div>
                            <div className="title">
                                <Card.Text
                                    style={{
                                        fontSize: 13,
                                        marginBottom: "0.3rem",
                                    }}
                                >
                                    <span style={{ fontWeight: 500 }}>
                                        Cara Order Mudah di
                                    </span>{" "}
                                    <span className="font-italic font-weight-bold">
                                        KONVEKSIPOLOSHIRT.COM
                                    </span>
                                </Card.Text>
                                <Card.Text
                                    style={{
                                        fontWeight: 500,
                                        fontStyle: "italic",
                                        fontSize: 10,
                                    }}
                                >
                                    jaman sekarang bikin kaos gak perlu pake
                                    ribet <br /> berikut 4 step order mudah di
                                    Gudang Seragam
                                </Card.Text>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} md={4} className="mb-4">
                    <Card className={styles.card}>
                        <Card.Body className="d-flex flex-column justify-content-around align-items-center">
                            <h5 className="text-center font-weight-bold">
                                KLIEN KAMI
                            </h5>
                            <Carousel
                                indicators={false}
                                controls={false}
                                pause={false}
                                touch={false}
                                interval={1500}
                                className="my-3"
                            >
                                {renderOurClient()}
                            </Carousel>
                            <Button
                                className={styles.btnMore}
                                as={Link}
                                to="/klien-kami"
                            >
                                LIHAT SEMUA
                            </Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} md={4} className="mb-4">
                    <Card className={styles.card}>
                        <Card.Body className="pb-0">
                            <h5
                                className="text-center font-weight-bold"
                                style={{ fontSize: "0.8rem" }}
                            >
                                6 LAYANAN GRATIS KAMI UNTUK ANDA
                            </h5>
                            <Row className="client-list mt-3 h-75">
                                {renderServices()}
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </section>
    );
};

export default Benefits;
